<template>
  <component
    :is="participantComponent"
    :isLoading="isLoading"
    :isRelay="config.isRelay"
    :isComplex="config.isComplex"
    :calcMethod="config.calcMethod"
    :event="eventProps"
    :race="raceProps"
    :status="result.status"
    :participant="participantProps"
    :participantInfo="participantInfoProps"
    :result="resultProps"
    :positions="positionsProps"
    :assets="assetsProps"
    :stages="stages"
    :substages="substages"
    @openParticipantDetails="$refs.participantDetails.open()"
  >
    <Modal
      name="participantDetails"
      ref="participantDetails"
      :title="result.fullName"
    >
      <template v-slot:subtitle>
        <div class="text-with-icon text-sm">
          <Icon
            class="icon"
            :name="result.gender === Gender.Male ? 'male-color' : 'female-color'"
            size="s"
          />
          <span>{{ genderAndAge }}</span>
        </div>
      </template>
      <div class="info-card" v-if="location">
        <img class="info-card__img" :src="themeImages['info-city']" alt="">
        <div class="info-card__title">{{ $t('participant.city') }}</div>
        <div><b>{{ location }}</b></div>
      </div>
      <div class="info-card" v-if="result.raceClub">
        <img class="info-card__img" :src="themeImages['info-club']" alt="">
        <div class="info-card__title">{{ $t('participant.club') }}</div>
        <div class="text-break"><b>{{ result.raceClub }}</b></div>
      </div>
    </Modal>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getDisciplineName } from '@/utils';
import {
  Gender, ResultStatus, ResultCalcMethod, ProfileVisibility,
} from '@/enums';
import resultApi from '@/services/results-api';
import Modal from '@/components/Modal/Modal.vue';

export default {
  name: 'Participant',
  components: {
    Modal,
  },
  props: {
    eventCode: String,
  },
  data() {
    return {
      Gender,
      ResultCalcMethod,
      ProfileVisibility,
      participantId: this.$route.params.participantId,
      raceCode: this.$route.params.raceCode,
      isLoading: false,
      result: {},
      config: {},
      stages: [],
      substages: [],
      participantsCount: {},
    };
  },
  computed: {
    ...mapGetters('event', ['event', 'raceInfo']),
    ...mapGetters(['countryById']),
    ...mapGetters('settings', ['theme', 'themeImages', 'themeUrls']),
    currentRace() {
      return this.event.races?.find((o) => o.code === this.raceCode) || {};
    },
    isBiathlon() {
      return (this.config?.disciplineCode || '').includes('biathlon');
    },
    participantComponent() {
      if (this.isBiathlon) {
        return () => import('@/sections/ParticipantDetailsBiathlon/ParticipantDetailsBiathlon.vue');
      }

      return () => import('@/sections/ParticipantDetails/ParticipantDetails.vue');
    },
    eventProps() {
      return {
        id: this.event.id,
        code: this.event.code,
        name: this.event.title,
        url: `${this.themeUrls.Events}/event/${this.event.code || this.event.id}`,
      };
    },
    location() {
      const country = this.countryById(this.result.countryCode)?.name;
      return [this.result.city, country]
        .filter((val) => val)
        .join(', ');
    },
    genderAndAge() {
      const { age } = this.result;
      return [
        this.result.gender === this.Gender.Male ? this.$tc('general.man') : this.$tc('general.woman'),
        age ? `${age} ${this.$tc('general.of_years', age)}` : null,
      ]
        .filter((val) => val)
        .join(' • ');
    },
    raceProps() {
      return {
        id: this.currentRace.id,
        code: this.currentRace.code,
        name: this.currentRace.name,
        waterTemperature: this.currentRace.waterTemperature,
        wetsuitAllowed: this.currentRace.wetsuitAllowed,
        disciplineCode: this.currentRace.disciplineCode,
      };
    },
    participantProps() {
      return this.result
        ? {
          id: this.participantId,
          name: this.result.fullName,
          avatar: this.result.avatarLargeImage && this.result.avatarLargeImage.replace('-x-', '176x176'),
          startNumber: this.result.number,
          category: this.result.genderAgeNominationName,
        } : {};
    },
    positionsProps() {
      const { calcMethod, calcMethodByCategory } = this.config;
      return this.result
        ? [
          {
            title: `${this.$t('result.position')} (${this.$t('result.position_cat')})`,
            value: calcMethodByCategory === ResultCalcMethod.Individual
              ? this.result.individualGenderAgePosition
              : this.result.absoluteGenderAgePosition,
            total: this.participantsCount.category,
          },
          {
            title: `${this.$t('result.position')} (${this.$t('result.position_gen')})`,
            value: calcMethod === ResultCalcMethod.Individual
              ? this.result.individualGenderPosition
              : this.result.absoluteGenderPosition,
            total: this.participantsCount.gender,
          },
          {
            title: `${this.$t('result.position')} (${this.$t('result.position_abs')})`,
            value: this.result.position,
            total: this.participantsCount.total,
          },
        ].filter((o) => o.value > 0)
        : [];
    },
    resultProps() {
      const { calcMethod, calcMethodByCategory } = this.config;
      return this.result
        ? {
          resultByCalcMethod: this.result.resultByCalcMethod,
          timeResultByCalcMethod: this.result.timeResultByCalcMethod,
          delayByCalcMethod: this.result.delayByCalcMethod,
          individualResult: this.result.individualResult,
          individualTimeResultOffset: this.result.individualTimeResultOffset,
          individualDelay: this.result.individualDelay,
          absoluteResult: this.result.absoluteResult,
          absoluteTimeResultOffset: this.result.absoluteTimeResultOffset,
          absoluteDelay: this.result.absoluteDelay,
          pace: this.result.pace,
          speed: this.result.speed,
          categoryPosition: calcMethodByCategory === ResultCalcMethod.Individual
            ? this.result.individualGenderAgePosition
            : this.result.absoluteGenderAgePosition,
          genderPosition: calcMethod === ResultCalcMethod.Individual
            ? this.result.individualGenderPosition
            : this.result.absoluteGenderPosition,
          position: this.result.position,
        }
        : {};
    },
    assetsProps() {
      const diplomaType = this.theme === 'rr' ? 'preview' : 'pdf';
      return {
        video: !this.config.hideVideo && this.config.hasVideo && this.result.video?.length
          ? this.result.video
          : null,
        diploma: !this.config.hideDiploma
          ? `${this.themeUrls.DiplomaUrl}/diploma.${diplomaType}?raceId=${this.currentRace.id}&participantId=${this.participantId}&language=${this.$lang}`
          : null,
        photo: !this.config.hidePhoto && this.config.photoUrlTemplate
        && this.result.status === ResultStatus.Finish
          ? `${this.config.photoUrlTemplate}/${this.result.number}`
          : null,
      };
    },
    participantInfoProps() {
      const {
        raceClub,
        raceClubCode,
        isExistingRaceClub,
      } = this.result;

      const club = raceClubCode && isExistingRaceClub
        ? `<a class="rr-link rr-link_primary" href="${this.themeUrls.Clubs}/club/${raceClubCode}" target="_blank">«${raceClub?.trim()}»</a>`
        : `<b class="club-name">«${raceClub?.trim()}»</b>`;

      return [
        {
          icon: this.result.gender === this.Gender.Male ? 'male-color' : 'female-color',
          text: this.genderAndAge,
        },
        {
          icon: 'environment',
          text: this.location,
        },
        {
          icon: 'user-groups',
          text: raceClub ? `${this.$t('participant.member_of_club')} ${club}` : null,
        },
      ].filter((o) => o.text);
    },
  },
  methods: {
    ...mapActions('event', ['getEvent', 'getRaceInfo']),
    setStages(stages) {
      if (!stages) {
        return;
      }

      const { calcMethod } = this.config;

      function mapStage(raceStage, resultStages) {
        const stage = (resultStages || []).find((o) => o.raceStageId === raceStage.id);
        const resultByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualResult
          : stage?.absoluteResult;
        const delayByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualDelay
          : stage?.absoluteDelay;

        return {
          ...(stage || {}),
          stageId: raceStage.id,
          name: raceStage.name,
          disciplineCode: raceStage.disciplineCode,
          isMissed: !stage,
          resultByCalcMethod: stage ? (resultByCalcMethod || '00:00') : null,
          delayByCalcMethod,
        };
      }

      const s = this.config.stagesInfo.map((raceStage) => {
        const resultStage = mapStage(raceStage, stages);
        resultStage.stages = raceStage.stagesInfo
          .map((raceSubstage) => mapStage(raceSubstage, resultStage.stageResults));
        return resultStage;
      });

      this.stages = this.config.isComplex
        ? s
        : [
          {
            stageId: '0000',
            pace: this.result.pace,
            speed: this.result.speed,
            name: getDisciplineName(this.currentRace.disciplineIcon, this.$lang),
            disciplineCode: this.currentRace.disciplineIcon,
            stages: s,
          },
        ];

      this.substages = this.stages.reduce((result, stage) => {
        if (!stage.stages) {
          return result;
        }
        return result.concat(stage.stages);
      }, []);
    },
    getParticipantDetails() {
      return resultApi.getParticipantProfile({
        participantId: this.participantId,
        eventId: this.event.id,
      })
        .then((response) => {
          const { data } = response;
          if (!data) {
            return;
          }

          const result = data.result && data.result.results[0];
          result.resultByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualResult
            : result.absoluteResult;
          result.timeResultByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualTimeResultOffset
            : result.absoluteTimeResultOffset;
          result.delayByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualDelay
            : result.absoluteDelay;
          this.result = result;

          this.participantsCount = {
            total: data.participantCount,
            category: data.categoryParticipantCount,
            gender: data.genderParticipantCount,
          };

          this.setStages(this.result.stageResults);
        });
    },
  },
  async created() {
    this.isLoading = true;
    await this.getEvent({
      eventIdOrCode: this.eventCode,
    });
    await this.getRaceInfo({
      raceId: this.currentRace.id,
    });
    this.config = this.raceInfo(this.currentRace.id) || {};
    await this.getParticipantDetails();
    this.isLoading = false;
  },
};
</script>
