<template>
  <ParticipantDetails
    :isLoading="isLoading"
    :isRelay="config.isRelay"
    :isComplex="config.isComplex"
    :calcMethod="config.calcMethod"
    :event="eventProps"
    :race="raceProps"
    :status="result.status"
    :participant="participantProps"
    :result="resultProps"
    :positions="positionsProps"
    :assets="assetsProps"
    :participants="participants"
    :stages="stages"
    :substages="substages"
    @openParticipantDetails="$refs.participantDetails.open()"
  >
    <Modal
      name="participantDetails"
      ref="participantDetails"
      :title="result.teamName"
    >
      <div class="team-members">
        <div
          class="team-members__item"
          v-for="(item, i) in participants"
          :key="i"
        >
          <div class="group-title group-title_sm group-title_sm_mb">{{ $t('result.stage') }} «{{ item.stageName || '--' }}»</div>
          <div class="team-member">
            <Member
              :name="item.name"
              :img="item.avatar"
            >
              <span class="text-with-icon">
                <Icon
                  class="icon"
                  :name="item.gender === Gender.Male ? 'male-color' : 'female-color'"
                  size="s"
                />
                <span class="text-xs">{{
                    item.gender === Gender.Male ? $tc('general.man') : $tc('general.woman')
                  }}</span>
              </span>
              <span class="text-xs" v-if="item.age">
                • {{ item.age }}&nbsp;{{ $tc('general.of_years', item.age) }}
              </span>
            </Member>
          </div>
        </div>
      </div>
    </Modal>
  </ParticipantDetails>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  Gender, ResultStatus, ResultCalcMethod, ProfileVisibility,
} from '@/enums';
import resultApi from '@/services/results-api';
import ParticipantDetails from '@/sections/ParticipantDetails/ParticipantDetails.vue';
import Modal from '@/components/Modal/Modal.vue';
import Member from '@/components/Member/Member.vue';

export default {
  name: 'Team',
  components: {
    ParticipantDetails,
    Modal,
    Member,
  },
  props: {
    eventCode: String,
  },
  data() {
    return {
      Gender,
      ProfileVisibility,
      participantId: this.$route.params.participantId,
      raceCode: this.$route.params.raceCode,
      isLoading: false,
      result: {},
      config: {},
      stages: [],
      substages: [],
      participantsCount: {},
    };
  },
  computed: {
    ...mapGetters('event', ['event', 'raceInfo']),
    ...mapGetters('settings', ['theme', 'themeUrls']),
    currentRace() {
      return this.event.races?.find((o) => o.code === this.raceCode) || {};
    },
    eventProps() {
      return {
        id: this.event.id,
        code: this.event.code,
        name: this.event.title,
        url: `${this.themeUrls.Events}/event/${this.event.code || this.event.id}`,
      };
    },
    raceProps() {
      return {
        id: this.currentRace.id,
        code: this.currentRace.code,
        name: this.currentRace.name,
        waterTemperature: this.currentRace.waterTemperature,
        wetsuitAllowed: this.currentRace.wetsuitAllowed,
        disciplineCode: this.currentRace.disciplineCode,
      };
    },
    participantProps() {
      return this.result
        ? {
          id: this.participantId,
          name: this.result.teamName,
          startNumber: this.result.number,
          category: this.result.genderAgeNominationName,
        } : {};
    },
    positionsProps() {
      return this.result
        ? [
          {
            title: `${this.$t('result.position')} (${this.$t('result.position_cat')})`,
            value: this.result.genderAgeNominationPosition,
            total: this.participantsCount.category,
          },
          {
            title: `${this.$t('result.position')} (${this.$t('result.position_abs')})`,
            value: this.result.position,
            total: this.participantsCount.total,
          },
        ].filter((o) => o.value > 0)
        : [];
    },
    resultProps() {
      return this.result
        ? {
          resultByCalcMethod: this.result.resultByCalcMethod,
          timeResultByCalcMethod: this.result.timeResultByCalcMethod,
          delayByCalcMethod: this.result.delayByCalcMethod,
          individualResult: this.result.individualResult,
          absoluteResult: this.result.absoluteResult,
          absoluteTimeResultOffset: this.result.absoluteTimeResultOffset,
          individualTimeResultOffset: this.result.individualTimeResultOffset,
          individualDelay: this.result.individualDelay,
          absoluteDelay: this.result.absoluteDelay,
          pace: this.result.pace,
          speed: this.result.speed,
        }
        : {};
    },
    assetsProps() {
      const diplomaType = this.theme === 'rr' ? 'preview' : 'pdf';
      return {
        video: !this.config.hideVideo && this.config.hasVideo && this.result.video?.length
          ? this.result.video
          : null,
        diploma: !this.config.hideDiploma
          ? `${this.themeUrls.DiplomaUrl}/diploma.${diplomaType}?raceId=${this.currentRace.id}&teamId=${this.participantId}&language=${this.$lang}`
          : null,
        photo: !this.config.hidePhoto && this.config.photoUrlTemplate
        && this.result.status === ResultStatus.Finish
          ? `${this.config.photoUrlTemplate}/${this.result.number || 0}`
          : null,
      };
    },
    participants() {
      return (this.result?.participants || []).map((o) => {
        const stages = this.stages
          .filter((stage) => stage.participantId === o.participantId && stage.stages.length);

        return {
          participantId: o.participantId,
          name: o.fullName,
          firstName: o.fullName?.split((' '))[1],
          lastName: o.fullName?.split((' '))[0],
          avatar: o.avatarLargeImage && o.avatarLargeImage.replace('-x-', '112x112'),
          gender: o.gender,
          age: o.age,
          location: null,
          club: null,
          stageName: stages[0]?.name,
        };
      });
    },
  },
  methods: {
    ...mapActions('event', ['getEvent', 'getRaceInfo']),
    setStages(stages) {
      if (!stages) {
        return;
      }

      const { participants } = this;
      const { calcMethod, isComplex } = this.config;
      const raceDisciplineIcon = this.currentRace.disciplineIcon;

      function mapStage(raceStage, resultStages) {
        const stage = (resultStages || []).find((o) => o.stageId === raceStage.id);
        const participant = stage && participants.find((p) => p.participantId === stage.participantId);
        const resultByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualResult
          : stage?.absoluteResult;
        const delayByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualDelay
          : stage?.absoluteDelay;

        return {
          ...(stage || {}),
          stageId: raceStage.id,
          name: raceStage.name,
          disciplineCode: isComplex
            ? raceStage.disciplineCode
            : raceDisciplineIcon,
          isMissed: !stage,
          resultByCalcMethod: stage ? (resultByCalcMethod || '00:00') : null,
          delayByCalcMethod,
          participant,
        };
      }

      this.stages = this.config.stagesInfo.map((raceStage) => {
        const resultStage = mapStage(raceStage, stages);
        resultStage.stages = raceStage.stagesInfo
          .map((raceSubstage) => mapStage(raceSubstage, resultStage.stagesResults));
        return resultStage;
      });

      this.substages = this.stages.reduce((result, stage) => {
        if (!stage.stages) {
          return result;
        }
        return result.concat(stage.stages);
      }, []);
    },
    getParticipantDetails() {
      return resultApi.getTeamProfile({
        teamId: this.participantId,
        eventId: this.event.id,
      })
        .then((response) => {
          const { data } = response;
          if (!data) {
            return;
          }

          const result = data.result && data.result.results[0];
          result.resultByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualResult
            : result.absoluteResult;
          result.timeResultByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualTimeResultOffset
            : result.absoluteTimeResultOffset;

          result.delayByCalcMethod = this.config.calcMethod === ResultCalcMethod.Individual
            ? result.individualDelay
            : result.absoluteDelay;
          this.result = result;

          this.participantsCount = {
            total: data.participantCount,
            category: data.categoryParticipantCount,
          };

          this.setStages(this.result.stagesResults);
        });
    },
  },
  async created() {
    this.isLoading = true;
    await this.getEvent({
      eventIdOrCode: this.eventCode,
    });
    await this.getRaceInfo({
      raceId: this.currentRace.id,
    });

    this.config = this.raceInfo(this.currentRace.id) || {};
    await this.getParticipantDetails();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "./Team";
</style>
