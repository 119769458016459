<template>
  <header class="tl-header">
    <div class="tl-top-headline d-none d-lg-block">
      <div class="container">
        <div class="tl-top-headline__row">
          <div class="tl-top-headline__submenu">
            <div class="tl-submenu">
              <a
                v-for="item in submenu"
                :key="item.text"
                class="tl-submenu__item"
                :href="item.href"
              >
                {{ item.text }}
              </a>
            </div>
          </div>
          <div class="tl-top-headline__menu">
            <div class="tl-menu">
              <template
                v-for="(item, i) in menu"
              >
                  <span
                    v-if="i !== 0"
                    :key="i"
                  >&nbsp;|&nbsp;</span>
                <a
                  :key="item.text"
                  class="tl-menu__item"
                  :class="{
                    'tl-menu__item_active': item.active,
                  }"
                  :href="item.href"
                >
                  {{ item.text }}
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tl-headline">
      <div class="container container_p-sm">
        <div class="tl-headline__row">
          <div class="tl-headline__item tl-headline__menu-btn d-lg-none">
            <Button
              variant="clear"
              shape="circle"
              icon-left="menu"
              icon-size="xl"
              @click="mobileNavOpened = true"
            ></Button>
          </div>
          <div class="tl-headline__item">
            <a class="tl-headline__logo" href="https://topliga.ru/">
              <img :src="themeImages.logo" alt="Высшая Лига">
            </a>
            <a v-if="isLive" :href="themeUrls.Live" class="tl-headline__live">
              <img src="./live-logo.svg" alt="Live">
            </a>
          </div>
          <div class="tl-headline__item tl-headline__user">
            <Button
              variant="clear"
              shape="circle"
              :href="avatarLink"
            >
              <Avatar
                :username="user && user.fullName"
                :img="user && user.avatarSmallUrl"
                bordered
              ></Avatar>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <HeaderMobileNav
      v-model="mobileNavOpened"
      :nav="mobileNav"
    >
      <template v-slot:logo>
        <div class="rr-mobile-nav__header-links">
          <a href="https://topliga.ru/">
            <img :src="themeImages.logo" alt="" width="154">
          </a>
          <a v-if="isLive" :href="themeUrls.Live">
            <img src="./live-logo.svg" alt="Live">
          </a>
        </div>
      </template>
    </HeaderMobileNav>
  </header>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import HeaderMobileNav from '@/components/HeaderMobileNav/HeaderMobileNav.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import Button from '@rr-component-library/button/src/main';
import eventsApi from '@/services/events-api';

const themeUrlsStart = store.getters['settings/themeUrls'];

export default {
  name: 'TopLigaHeader',
  components: {
    HeaderMobileNav,
    Avatar,
    Button,
  },
  data() {
    return {
      mobileNavOpened: false,
      submenu: [
        {
          text: 'Контакты',
          href: 'https://topliga.ru/company/contacts/',
        },
        {
          text: 'Доставка',
          href: 'https://topliga.ru/company/delivery/',
        },
        {
          text: 'Возврат',
          href: 'https://topliga.ru/company/delivery/#return',
        },
      ],
      menu: [
        {
          text: 'Магазин',
          href: 'https://topliga.ru/',
          icon: 'shopping-bag',
        },
        {
          text: 'Старты',
          href: `${themeUrlsStart.Main}/events`,
          icon: 'calendar',
        },
        {
          text: 'Результаты',
          href: themeUrlsStart.Results,
          icon: '1st-place-medal',
          active: true,
        },
        {
          text: 'Фото',
          href: 'http://photo.topliga.ru',
          icon: 'camera',
        },
        {
          text: 'Клуб',
          href: themeUrlsStart.Clubs,
          icon: 'user-groups',
        },
        {
          text: 'Тренировочные сборы',
          href: 'https://topliga.ru/camp/',
          icon: 'basketball-jersey',
        },
      ],
      isLive: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeImages', 'themeSettings', 'themeUrls']),
    ...mapGetters('user', ['user', 'isAuthorized']),
    avatarLink() {
      let platform = window.location.hostname.split('.')[0].split('-')[1];
      platform = platform ? `${platform}.` : '';

      let mainLink = this.themeUrls.Main;

      const devLoginLink = `https://${platform}rr-dev.ru`;
      const isDev = [
        window.location.hostname.includes('rr-dev'),
        window.location.hostname.includes('localhost'),
      ].some(Boolean);

      if (isDev) {
        mainLink = devLoginLink;
      }

      return !this.isAuthorized ? `${mainLink}/login` : `${mainLink}/Account`;
    },
    mobileNav() {
      return [
        ...this.menu,
        { divider: true },
        ...this.submenu,
      ];
    },
  },
  methods: {
    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.isLive = true;
      }
    },
  },
  mounted() {
    if (!this.themeSettings.noLive) {
      this.getLiveCount();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./Header";
</style>
