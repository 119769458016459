import { mapGetters } from 'vuex';
import { Gender, ResultStatus, ResultCalcMethod } from '@/enums';
import { formatPaceText } from '@/utils';
import Member from '@/components/Member/Member.vue';
import CompareButton from '@/components/CompareButton/CompareButton.vue';
import TimeOffset from '@/components/TimeOffset/TimeOffset.vue';
import ResultCard from './_ResultCard.vue';
import ResultPosition from './_ResultPosition.vue';
import './Results.scss';

export default {
  components: {
    ResultCard,
    ResultPosition,
    CompareButton,
    TimeOffset,
    Member,
  },
  props: {
    raceId: String,
    config: Object,
    results: Array,
    stages: Array,
    hideCategory: Boolean,
    idHighlighted: String,
    compareList: {
      type: Array,
      default: () => [],
    },
    positionBy: String,
  },
  data() {
    return {
      Gender,
      ResultStatus,
      ResultCalcMethod,
      ReversedResultStatus: this.$reversedEnum(ResultStatus),
      openedResult: null,
      chipTooltip: this.$t('event_results.chip_time_tooltip'),
      gunTooltip: this.$t('event_results.gun_time_tooltip'),
      absoluteLeadersCount: this.config.countOfAbsoluteLeaders || 0,
    };
  },
  computed: {
    ...mapGetters([
      'countryById',
    ]),
    ...mapGetters('settings', ['theme', 'themeUrls']),
    positionByKey() {
      const calcMethod = !this.config.isRelay
      && (this.positionBy === 'category')
        ? this.config.calcMethodByCategory
        : this.config.calcMethod;

      const type = calcMethod === ResultCalcMethod.Individual
        ? 'individual'
        : 'absolute';

      const positionKeys = {
        category: !this.config.isRelay
          ? `${type}GenderAgePosition`
          : 'genderAgeNominationPosition',
        gender: `${type}GenderPosition`,
        age: `${type}AgePosition`,
      };

      switch (this.positionBy) {
        case 'category':
          return positionKeys.category;
        case 'gender':
          return positionKeys.gender;
        case 'age':
          return positionKeys.age;
        default:
          return 'position';
      }
    },
    positionByText() {
      switch (this.positionBy) {
        case 'category':
        case 'age':
          return this.$t('result.position_cat');
        case 'gender':
          return this.$t('result.position_gen');
        default:
          return this.$t('result.position_abs');
      }
    },
    calcMethod() {
      return this.positionBy === 'category' || this.positionBy === 'age'
        ? this.config.calcMethodByCategory
        : this.config.calcMethod;
    },
  },
  methods: {
    openResult(id) {
      if (this.openedResult === id) {
        this.openedResult = null;
        return;
      }
      this.openedResult = id;
      setTimeout(() => {
        const el = this.$el.querySelector(`[data-id="${id}"]`);
        if (el) {
          window.scrollTo({
            top: el.getBoundingClientRect().top + window.pageYOffset - 72,
            behavior: 'smooth',
          });
        }
      }, 100);
    },
    getDiplomaUrl(participantId) {
      const diplomaType = this.theme === 'rr' ? 'preview' : 'pdf';

      return `${this.themeUrls.DiplomaUrl}/diploma.${diplomaType}?raceId=${this.raceId}&participantId=${participantId}&language=${this.$lang}`;
    },
    getTeamDiplomaUrl(teamId) {
      const diplomaType = this.theme === 'rr' ? 'preview' : 'pdf';
      return `${this.themeUrls.DiplomaUrl}/diploma.${diplomaType}?raceId=${this.raceId}&teamId=${teamId}&language=${this.$lang}`;
    },
    getPhotoUrl(number) {
      return this.config.photoUrlTemplate
        ? `${this.config.photoUrlTemplate}/${number || 0}`
        : null;
    },
    getShortName(fullName) {
      if (!fullName) {
        return null;
      }
      const name = fullName.split(' ');
      return `${name[0]} ${name[1][0]}.`;
    },
    formatLocation(city, countryCode) {
      return [city, this.countryById(countryCode)?.name]
        .filter((o) => o)
        .join(', ');
    },
    formatPaceText,
  },
};
