<template>
  <section class="section_pt section_pb">
    <div class="container container_wide">
      <div class="container__mobile">
        <div class="block block_wide p-0" style="max-width: 1312px;">
          <div class="section-block__group section-block__group_bordered-bottom">
            <Row class="rr-grid_8" align="center" no-wrap>
              <Col class="mr-auto flex-shrink" :size="{md: 'auto'}">
                <Select
                  class="results-select"
                  v-model="race.val"
                  :options="race.options"
                  track-by="code"
                  dropdown-auto-width
                  wrap-text
                  mobile
                  @input="onRaceChange"
                ></Select>
              </Col>
              <Col size="auto" class="d-lg-none" v-show="currentRace.judgeFullName || currentRace.waterTemperature != null">
                <Button
                  variant="secondary"
                  shape="square"
                  icon-left="info-circle"
                  @click.prevent="$refs.raceInfo.open()"
                  mobile
                ></Button>
              </Col>
              <Col size="auto" class="d-none d-md-block">
                <Row class="rr-grid_40">
                  <Col
                    size="auto"
                    class="d-none"
                    :class="currentRace.judgeFullName && 'd-lg-block'"
                  >
                    <div class="text-xs">{{ $t('event_results.judge') }}</div>
                    <div><b class="v-align-middle">{{ currentRace.judgeFullName }}</b></div>
                  </Col>
                  <Col
                    size="auto"
                    class="d-none"
                    :class="currentRace.waterTemperature != null && 'd-lg-block'"
                  >
                    <div class="text-xs">{{ $t('event_results.water_t') }}</div>
                    <div class="text-with-icon text-nowrap">
                      <span><b>{{ currentRace.waterTemperature }}°C</b></span>
                      <img v-show="currentRace.wetsuitAllowed" class="icon" src="@/assets/img/diving-suit.svg" alt="" width="18" height="18">
                    </div>
                  </Col>
                  <Col size="auto" v-show="!config.hideDownloadProtocol && config.resultTemplates && config.resultTemplates.length">
                    <Button
                      variant="secondary"
                      icon-left="download"
                      :text="$t('event_results.download_protocol')"
                      @click="$root.$modals.protocolModal.open()"
                      mobile
                    ></Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <ResultsFilters
            ref="filters"
            class="section-block__group section-block__group_bordered-bottom"
            :isRelay="currentRace.isRelay"
            @change="onFiltersChange"
            @reset="onFiltersChange"
          ></ResultsFilters>
          <Loader v-if="isLoading"></Loader>
          <div ref="topOfResults"></div>
          <div style="position: relative">
            <Loader
              class="rr-loader_overlay_top"
              v-show="!isLoading && isLoadingResults && !isLoadingMore"
              overlay
            ></Loader>
            <!-- Not found : BEGIN -->
            <div class="block__group" v-show="notFound">
              <EmptyState
                :subtitle="$t('event_results.participants_not_found')"
              ></EmptyState>
            </div>
            <!-- Not found : END -->
            <!-- Server error : BEGIN -->
            <div class="block__group" v-show="serverError">
              <EmptyState
                :subtitle="$t('event_results.loading_error')"
              >
                <div class="section__group">
                  <Button
                    variant="secondary"
                    :text="$t('general.retry')"
                    size="lg"
                    icon-left="undo"
                    @click.prevent="retryLoadResults()"
                  ></Button>
                </div>
              </EmptyState>
            </div>
            <!-- Server error : END -->
            <div class="section-block__group pb-0" v-show="results.length" v-if="!config.hideParticipantCount">
              <div
                class="text-sm color-black-65"
                v-html="$tc(
                  'table.showing_records',
                  pagination.totalItems,
                  [`${pagination.end - results.length + 1}-${pagination.end || 0}`, pagination.totalItems]
                )"
              >
              </div>
            </div>

            <!-- Results : BEGIN -->
            <div class="section-block__group" v-if="!isLoading && !notFound && !serverError">
              <Alert
                v-if="sizes.isMobile && !config.isComplex && !config.isRelay"
                class="results-mobile-info"
                :text="resultsAlertInfo.text"
                :icon="resultsAlertInfo.icon"
                noAction
                state="warning" />
              <component
                v-if="showCompactResults"
                :is="!isRelayTable ? 'IndividualResultsCompact' : 'RelayResultsCompact'"
                :hideCompareButton="visibilityCompareButton"
                :raceId="currentRace.id"
                :config="config"
                :results="results"
                :stages="stages"
                :idHighlighted="idHighlighted"
                isOpenable
                :positionBy="positionBy"
                :compareList="raceCompareList"
                @compare="compareResult"
              ></component>
              <component
                v-else-if="isBiathlon"
                :is="'BiathlonResults'"
                :hideCompareButton="visibilityCompareButton"
                :raceId="currentRace.id"
                :config="config"
                :results="results"
                :stages="stages"
                :idHighlighted="idHighlighted"
                isOpenable
                :positionBy="positionBy"
                :compareList="raceCompareList"
                @compare="compareResult"
              ></component>
              <component
                v-else
                :is="!isRelayTable ? 'IndividualResultsTable' : 'RelayResultsTable'"
                :hideCompareButton="visibilityCompareButton"
                :raceId="currentRace.id"
                :config="config"
                :results="results"
                :stages="stages"
                :idHighlighted="idHighlighted"
                isOpenable
                :hideCategory="hideCategory"
                :positionBy="positionBy"
                :compareList="raceCompareList"
                @compare="compareResult"
                @openVideoFinish="openVideoFinish"
              ></component>
            </div>
            <!-- Results : END -->
          </div>
          <!-- Pagination : BEGIN -->
          <div class="section-block__group pt-0">
            <div class="block__items" v-show="!isLoading && results.length">
              <div class="block__item" v-if="pagination.end < pagination.totalItems">
                <Button
                  :text="$t('general.show_more')"
                  variant="secondary"
                  size="lg"
                  wide
                  mobile
                  :disabled-quiet="isLoadingResults"
                  :loading="isLoadingMore"
                  @click.prevent="showMore"
                ></Button>
              </div>
              <Row align="center" justify="center" v-show="!isLoading && results.length">
                <Col
                  class="block__item mr-auto"
                  :size="{ md: 'auto'}"
                >
                  <Pagination
                    :page="pagination.page - 1"
                    :totalItems="pagination.totalItems"
                    :itemsPerPage="pagination.pageSize"
                    @change="changePage($event + 1)"
                    @rangeChange="rangeChange"
                  ></Pagination>
                </Col>
                <Col
                  class="block__item"
                  size="auto"
                  v-show="pagination.totalItems > 15"
                >
                  <div class="text-sm">
                    <span class="v-align-middle">{{ $t('table.show') }}</span>&nbsp;
                    <Select
                      v-model="pagination.pageSize"
                      :value="pagination.pageSize"
                      :options="pagination.pageSizes"
                      size="sm"
                      placeholder=""
                      style="width: 68px;"
                      @input="changePageSize"
                    ></Select>
                    &nbsp;<span class="v-align-middle">{{ $tc('table.records', pagination.pageSize) }}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <!-- Pagination : END -->
        </div>
      </div>
    </div>
    <Modal
      name="raceInfo"
      ref="raceInfo"
      :title="event.title"
      :subtitle="currentRace.name"
    >
      <div class="info-card" v-show="currentRace.judgeFullName">
        <img class="info-card__img" :src="themeImages['info-trainer']" alt="">
        <div class="info-card__title">{{ $t('event_results.judge') }}</div>
        <div><b>{{ currentRace.judgeFullName || '--' }}</b></div>
      </div>
      <div class="info-card" v-show="currentRace.waterTemperature != null">
        <img class="info-card__img" src="@/assets/img/info-water.svg" alt="">
        <div class="info-card__title">{{ $t('event_results.water_t') }}</div>
        <div class="text-with-icon text-nowrap">
          <span><b>{{ currentRace.waterTemperature || '--' }}°C</b></span>
          <img v-show="currentRace.wetsuitAllowed" class="icon" src="@/assets/img/diving-suit.svg" alt="" width="18" height="18">
        </div>
      </div>
    </Modal>
    <ResultsProtocol
      :raceId="currentRace.id"
      :templates="config.resultTemplates"
      :calculatedDate="config.resultsCalculatedDate"
      :isFinal="config.isFinalProtocol"
      :timingType="currentRace.timingType"
    ></ResultsProtocol>
    <VideoFinish ref="videoFinish"></VideoFinish>
    <slot name="myLaps"></slot>
  </section>
</template>

<script>
import { ResultCalcMethod } from '@/enums';
import { groupArray } from '@/assets/js/helpers';
import { mapActions, mapGetters } from 'vuex';
import resultsApi from '@/services/results-api';
import Select from '@rr-component-library/select/src/main';
import Pagination from '@/components/Pagination/Pagination.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import Modal from '@/components/Modal/Modal.vue';
import VideoFinish from '@/sections/VideoFinish/VideoFinish.vue';
import ResultsFilters from './ResultsFilters/ResultsFilters.vue';
import ResultsProtocol from './ResultsProtocol/ResultsProtocol.vue';

export default {
  name: 'Results',
  components: {
    Select,
    Pagination,
    ResultsFilters,
    EmptyState,
    Modal,
    VideoFinish,
    IndividualResultsTable: () => import('@/sections/Results/IndividualResultsTable.vue'),
    RelayResultsTable: () => import('@/sections/Results/RelayResultsTable.vue'),
    IndividualResultsCompact: () => import('@/sections/Results/IndividualResultsCompact.vue'),
    RelayResultsCompact: () => import('@/sections/Results/RelayResultsCompact.vue'),
    BiathlonResults: () => import('@/sections/Results/BiathlonResults/BiathlonResults.vue'),
    ResultsProtocol,
  },
  props: {
    eventCode: String,
    event: Object,
    eventId: String,
    race: Object,
  },
  data() {
    return {
      ResultCalcMethod,
      config: {},
      stages: [],
      results: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageSizes: [
          { id: 15, name: '15' },
          { id: 25, name: '25' },
          { id: 50, name: '50' },
        ],
        totalItems: 0,
        start: null,
        end: null,
        loadingPage: 1,
      },
      isLoading: false,
      isLoadingResults: false,
      isLoadingMore: false,
      notFound: false,
      serverError: false,
      idToHighlight: null,
      idHighlighted: null,
      positionBy: null,
      isRelayTable: false,
    };
  },
  inject: ['sizes'],
  computed: {
    finalCalcMethod() {
      return this.positionBy === 'category' ? this.config.calcMethodByCategory : this.config.calcMethod;
    },
    resultsAlertInfo() {
      return {
        text: this.finalCalcMethod === ResultCalcMethod.Individual ? this.$t('general.resultsChip') : this.$t('general.resultsAbsolute'),
        icon: this.finalCalcMethod === ResultCalcMethod.Individual ? 'stopwatch' : 'gun',
      };
    },
    ...mapGetters('event', ['raceInfo']),
    ...mapGetters('follow', ['getFollowListOfRace']),
    ...mapGetters('settings', ['themeImages', 'theme']),
    currentRace() {
      return this.race.options.find((o) => o.code === this.race.val) || {};
    },
    isBiathlon() {
      return (this.config?.disciplineCode || '').includes('biathlon');
    },
    showCompactResults() {
      return window.innerWidth < 768;
    },
    hideCategory() {
      return this.config.hideGenderAge
        || (this.currentRace.filters && (this.currentRace.filters.genderAgeNominations && !this.currentRace.filters.genderAgeNominations.length));
    },
    raceCompareList() {
      return this.getFollowListOfRace({
        eventCode: this.eventCode,
        raceId: this.currentRace.id,
      });
    },
    visibilityCompareButton() {
      const disableOnTenants = ['pionercup'];
      return disableOnTenants.includes(this.theme);
    },
  },
  methods: {
    ...mapActions('event', ['getRaceInfo']),
    ...mapActions('follow', ['toggleFollow']),
    openVideoFinish(video) {
      this.$refs.videoFinish.open(video);
    },
    changePage(page) {
      this.pagination.loadingPage = page;
      this.onParamsChange();
    },
    changePageSize() {
      this.pagination.loadingPage = 1;
      this.onParamsChange();
      this.$emit('pageSizeChanged', this.pagination.pageSize);
    },
    rangeChange(start, end) {
      this.pagination.start = start + 1;
      this.pagination.end = end;
    },
    showMore() {
      this.pagination.loadingPage = this.pagination.page + 1;
      this.isLoadingMore = true;
      this.onParamsChange();
    },
    onParamsChange() {
      this.idHighlighted = null;
      this.setQuery();
      this.loadResults();
    },
    setQuery() {
      const query = {
        ...this.$route.query,
        page: this.pagination.loadingPage > 1 ? this.pagination.loadingPage : undefined,
        pageSize: this.pagination.pageSize !== 25 ? this.pagination.pageSize : undefined,
      };
      this.$router.replace({ query })
        .catch(() => {});
    },
    parseQuery() {
      const { query } = this.$route;
      if (query.page) {
        this.pagination.loadingPage = +query.page;
      }
      if (query.pageSize && this.pagination.pageSizes.some((o) => o.id === +query.pageSize)) {
        this.pagination.pageSize = +query.pageSize;
      }
    },
    async onRaceChange() {
      await this.loadRaceInfo();
      this.pagination.loadingPage = 1;
      this.$refs.filters.setData(this.currentRace.filters);
      this.$refs.filters.reset();
      this.onParamsChange();
    },
    onFiltersChange() {
      this.pagination.loadingPage = 1;
      this.onParamsChange();
    },
    async loadRaceInfo() {
      await this.getRaceInfo({
        raceId: this.currentRace.id,
      });

      this.config = this.raceInfo(this.currentRace.id);
      this.stages = this.config.stagesInfo || [];
    },
    mapResults(results) {
      const {
        calcMethod: calcMethodByAbsolute,
        calcMethodByCategory,
        isComplex,
        isRelay,
      } = this.config;
      const calcMethod = !isRelay
      && (this.positionBy === 'category' || this.positionBy === 'age')
        ? calcMethodByCategory
        : calcMethodByAbsolute;
      const stagesKey = isRelay ? 'stagesResults' : 'stageResults';
      const stageIdKey = isRelay ? 'stageId' : 'raceStageId';
      const raceStages = this.config.stagesInfo || [];
      const raceDisciplineIcon = this.currentRace.disciplineIcon;

      function mapStage(raceStage, resultStages) {
        const stage = (resultStages || []).find((o) => o[stageIdKey] === raceStage.id);

        const resultByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualResult
          : stage?.absoluteResult;
        const delayByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualDelay
          : stage?.absoluteDelay;
        return {
          ...(stage || {}),
          stageId: raceStage.id,
          name: raceStage.name,
          disciplineCode: isComplex || !isRelay
            ? raceStage.disciplineCode
            : raceDisciplineIcon,
          isMissed: !stage,
          resultByCalcMethod: stage ? (resultByCalcMethod || '00:00') : null,
          delayByCalcMethod,
        };
      }

      return results.map((result, index) => {
        const participants = groupArray(result.participants, 'participantId');

        const resultStages = raceStages.map((raceStage) => {
          let resultStage = mapStage(raceStage, result[stagesKey]);
          if (this.isBiathlon && resultStage) {
            const biathlonConfig = {};
            biathlonConfig.isBiathlon = true;
            biathlonConfig.showPenalty = resultStage.stageResults?.length > 0;
            biathlonConfig.penaltyAmount = (resultStage.stageResults || []).reduce((acc, substage) => {
              const isPenalty = substage?.isFine;

              return acc + (isPenalty ? 1 : 0);
            }, 0);

            resultStage = { ...resultStage, ...biathlonConfig };
          }

          if (this.config.isRelay) {
            resultStage.participant = participants[resultStage.participantId];
          }

          resultStage[stagesKey] = raceStage.stagesInfo
            .map((raceSubstage) => mapStage(raceSubstage, resultStage[stagesKey]));
          return resultStage;
        });

        const biathlonConfig = {};

        if (this.isBiathlon && resultStages.length > 0) {
          biathlonConfig.isBiathlon = true;
          biathlonConfig.showPenalty = index > 0;

          biathlonConfig.penaltyAmount = (resultStages || []).reduce((acc, stage) => acc + stage.penaltyAmount, 0);
        }

        return {
          ...result,
          ...biathlonConfig,
          resultByCalcMethod: calcMethod === ResultCalcMethod.Individual
            ? result.individualResult
            : result.absoluteResult,
          timeResultByCalcMethod: calcMethod === ResultCalcMethod.Individual
            ? result.individualTimeResultOffset
            : result.absoluteTimeResultOffset,
          delayByCalcMethod: calcMethod === ResultCalcMethod.Individual
            ? result.individualDelay
            : result.absoluteDelay,
          [stagesKey]: resultStages,
        };
      });
    },
    retryLoadResults() {
      if (this.isLoadingResults) {
        return;
      }
      this.isLoadingResults = true;
      setTimeout(this.loadResults, 3000);
    },
    loadResults() {
      this.isLoadingResults = true;
      const page = this.pagination.loadingPage;
      const method = this.config.isRelay
        ? 'getRelayResults'
        : 'getIndividualResults';

      let responseStatus = null;
      let preventScrollToTop = false;
      const filters = this.$refs.filters.getFilters();
      this.positionBy = this.getPositionBy(filters);

      resultsApi[method]({
        eventId: this.eventId,
        raceId: this.currentRace.id,
        page: {
          skip: (page - 1) * this.pagination.pageSize,
          take: this.pagination.pageSize,
        },
        filter: filters,
        isStagesOn: true,
      })
        .then((response) => {
          if (!this.isLoadingMore) {
            this.results = [];
          }
          this.isRelayTable = this.config.isRelay;

          const idToHighlight = this.idToHighlight !== this.idHighlighted
            ? this.idToHighlight
            : null;

          const { data } = response;
          if (data) {
            const results = this.mapResults(data.results || []);

            this.results.push(...results);
            this.pagination.page = page;
            this.pagination.totalItems = data.totalCount || 0;

            // Highlight result
            if (idToHighlight) {
              this.highlightResult(idToHighlight);
            }
          }

          this.notFound = !this.results.length;
          responseStatus = response.status;
          preventScrollToTop = idToHighlight || this.isLoadingMore;
        })
        .catch((error) => {
          responseStatus = error.response.status;
          this.notFound = responseStatus === 404;
          this.results = [];
        })
        .finally(() => {
          this.pagination.loadingPage = this.pagination.page;
          this.isLoadingResults = false;
          this.isLoadingMore = false;
          this.isLoading = false;
          this.serverError = responseStatus !== 200 && responseStatus !== 404;
          if (!preventScrollToTop) {
            const scrollPosition = this.$refs.topOfResults
              && this.$refs.topOfResults.getBoundingClientRect().top + window.pageYOffset - 72;
            if (scrollPosition < window.pageYOffset) {
              window.scrollTo(0, scrollPosition);
            }
          }
        });
    },
    getPositionBy(filters) {
      if (!filters) {
        return null;
      }
      if (filters.nominationId || filters.genderAgeNominationId) {
        return 'category';
      }
      if (filters.genderNominationId) {
        return 'gender';
      }
      if (filters.ageNominationId) {
        return 'age';
      }
      return null;
    },
    compareResult(id) {
      this.toggleFollow({
        id,
        eventCode: this.eventCode,
        raceId: this.currentRace.id,
      });
    },
    async findResult({ id, page, raceCode }) {
      if (raceCode) {
        this.race.val = raceCode;
        await this.loadRaceInfo();
        this.$refs.filters.setData(this.currentRace.filters);
      }
      const result = this.$el.querySelector(`[data-id="${id}"]`);
      if (result) {
        this.highlightResult(id);
      } else {
        this.idToHighlight = id;
        this.idHighlighted = null;
        this.$refs.filters.reset();
        this.pagination.loadingPage = page;
        this.onParamsChange();
      }
    },
    highlightResult(id) {
      this.idToHighlight = null;
      this.idHighlighted = id;
      setTimeout(() => {
        const result = this.$el.querySelector(`[data-id="${id}"]`);
        if (result) {
          window.scrollTo({
            top: result.getBoundingClientRect().top + window.pageYOffset - 72,
            behavior: 'smooth',
          });
        }
      }, 100);
    },
  },
  async mounted() {
    this.isLoading = true;
    this.parseQuery();
    this.$refs.filters.setData(this.currentRace.filters);
    await this.loadRaceInfo();
    this.loadResults();
  },
};
</script>

<style lang="scss" scoped>
.results-select {

  @media (min-width: breakpoint(md)) {
    max-width: 328px;

    ::v-deep .multiselect__content-wrapper {
      min-width: 200px;
    }
  }
}

.results-mobile-info {
  margin-bottom: 20px;
}
</style>
